<template>
    <div  style="height:100%;background:#fff" >
        <div style="padding-top:2px; background:white;line-height:30px;height:36px;width:100%;border-bottom:2px solid #389">
            <div style=" display:flex; min-width:1450px;  ">
                <div style="width:100px; line-height:30px" >
                    <i class="iconfont icon-xiashuangjiantou" style="color:#409EFF;font-size:12px;margin-right:5px; "></i>
                    <el-link   @click="nextSet" :underline="false"  ><span style="font-size:11px;margin-right:10px;">下推采购单 </span></el-link> 
                </div>
                <div style="margin-right:10px;font-size:12px;margin-left:5px;color:#389;font-weight:700">状态</div>
                <el-select size="small" v-model="stfgSet" @change="stfgChange" style=" width:170px;" >
                    <el-option  label="所有" value="ALL" ></el-option> 
                    <span v-for="(item,index) in stfgArr" @change="stfgChg" :key="index" >
                        <el-option  :label=" item.CNAME" :value="item.SUBNO" ></el-option> 
                    </span>
                </el-select> 
                <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:130px; " >
                    <span v-for="item in ordCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key"></el-option>
                    </span>
                </el-select>
                <el-input clearable :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="stfgChange" size="small" style="width:320px;height:30px; ">
                    <el-select v-model="oper" slot="prepend"   style="width:110px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input>

                <el-select v-model="selparam2" size="small" placeholder="请选择列名" clearable  style="width:130px;margin-left:5px; " >
                    <span v-for="item in ordCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key"></el-option>
                    </span>
                </el-select>
                <el-input :placeholder="input_hold" v-model="txtparam2" @keyup.enter.native="stfgChange" size="small" style="width:320px; height:30px; ">
                    <el-select v-model="oper2" slot="prepend"   style="width:110px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input>
 
            </div>
        </div>  
        <div>
        <el-table  :data="poData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="400" 
              :row-key="getRowKey"
              ref="refpo"
              show-summary
              :summary-method="getSummary"
              highlight-current-row
              :row-class-name="tableRowClass"
              style="overflow:auto;margin-top:1px;"
              @row-click="rowClick" > 
            <el-table-column  v-for="(item,index ) in ordCol.filter(item=>item.thide=='N')" :key="index" 
                :prop="item.key"
                :label="item.title"
                show-overflow-tooltip
                :align="item.align"                                  
                :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                :min-width="item.width">
                <template slot-scope="scope" >
                    <el-checkbox v-if="item.edcss=='checkbox'" :disabled="item.key!=='SEL'" false-label="N" true-label="Y" v-model="scope.row[item.key]" >&nbsp;</el-checkbox>
                    <span v-else v-text="scope.row[item.key]"></span>
                </template>
            </el-table-column> 
             <el-table-column
                fixed="right"
                label="状态操作"
                align="center"
                width="145">
                <template slot-scope="scope">
                    <el-button size="mini"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,scope.$index)">编辑</el-button>
                    <el-button size="mini"  type="text" icon="iconfont icon-duanxin1" @click="mailRec(scope.row )">邮件通知</el-button>
                    <!-- <el-button size="mini" v-if="scope.row.ORDSTFG==='Y'"  type="text" icon="iconfont icon-fanshenhe" @click="stfgRec(scope.row,'N')">未审核</el-button> 
                    <el-button size="mini" v-if="scope.row.ORDSTFG!=='Y'"  type="text" icon="iconfont icon-shanchu" @click="stfgRec(scope.row,'X')">作废</el-button>    -->
                </template>
            </el-table-column>  
        </el-table>
        </div>
        <div style="line-height:30px;height:34px ;background:#808695;color:white;padding-left:2px">
            <i class="iconfont icon-xuanze1" style="margin-right:5px"></i><span style="font-size:12px;font-weight:600"> 订单明细   
                【 <el-link :underline="false" @click="addDtl" style="color:white;border-bottom:1px solid #fff">新增明细记录</el-link> 】
            </span>

        </div>
        <el-table  :data="dtlData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              :row-key="getRowKey2"
              show-summary
              :summary-method="getDtlSum"
              ref="refdtl"
              highlight-current-row
              style="overflow:auto;margin-top:1px;" > 
                <el-table-column  v-for="(item ) in dtlCol.filter(item=>item.thide=='N'&&item.key!=='SEL'&&item.key!=='ISNEXT'&&item.key!=='NEXTQTY')" :key="item.key"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    :sortable="item.edcss!=='checkbox'&&item.key!=='RN'"
                    :min-width="item.width">
                    <template slot-scope="scope" >  
                        <span v-if="item.edcss=='checkbox' ">
                            <el-checkbox  true-label="Y" disabled  false-label="N" v-model="scope.row[item.key]" style="width:100%"> &nbsp;</el-checkbox> 
                        </span>
                        <span v-else  >{{scope.row[item.key]}}</span>
                    </template>
                </el-table-column> 
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="130">
                <template slot-scope="scope">
                    <el-button size="mini"  type="text" icon="iconfont icon-bianji" @click="dtlEdit(scope.row,scope.$index)">编辑</el-button>
                    <el-button size="mini"  type="text" icon="iconfont icon-shanchu1" @click="delRec(scope.row )">删除</el-button>
 
                </template>
            </el-table-column>  
        </el-table>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 主记录 -->
        <el-dialog   v-dialogDrag :visible.sync="addWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <Form  :model="ordRow"  :label-width="150" style="width:99%"  label-position="right" inline > 
                <span v-for="(item,index) in ordCol" :key="index" >
                    <FormItem :label="item.title "  class="item-width" v-if="item.fhide==='N'" :prop="item.key">               
                        <el-select v-model="ordRow[item.key]"   v-if="item.key==='STFG'" style="width:410px">
                            <span v-for="(item,index) in stfgArr" :key="index"  >
                                <el-option  :label=" item.CNAME" :value="item.SUBNO" ></el-option> 
                            </span>
                        </el-select>                     
                        <el-input-number   v-else-if="item.edcss==='number'" v-model="ordRow[item.key]" style="width:100%" ></el-input-number>   
                        <el-input  v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="ordRow[item.key]" ></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="addWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun"  @click="saveRec" style="margin-right:10px;">保存</el-button>         
                </span>
            </div>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">编辑记录</span>
            </div> 
        </el-dialog>
        <!-- 明细记录 -->
        <el-dialog   v-dialogDrag :visible.sync="dtlWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <Form  :model="dtlRow"  :label-width="120" style="width:99%"  label-position="right" inline > 
                <span v-for="(item,index) in dtlCol" :key="index" >
                    <FormItem :label="item.title "  class="item-width" v-if="item.fhide==='N'" :prop="item.key">               
                        <el-select v-model="dtlRow[item.key]" filterable  @change="matChg" v-if="item.key==='MATIDSEQ'" style="width:100%">
                            <el-option v-for="(item,index) in matArr" :key="index" :label="item.IDNO" :value="item.IDSEQ" >
                                <span style="float:left">{{item.MATNM}}</span> 
                                <span style="float:right;color:#808695">{{item.IDNO}}</span> 
                            </el-option>
                        </el-select>                     
                        <el-input-number @change="numChg"  v-else-if="item.edcss==='number'" v-model="dtlRow[item.key]" style="width:100%" ></el-input-number>   
                        <el-input  v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="dtlRow[item.key]" ></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="dtlWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun"  @click="dtlSave" style="margin-right:10px;">保存</el-button>         
                </span>
            </div>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">明细记录</span>
            </div> 
        </el-dialog>
        <!-- 采购明细 -->
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="poWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">订单明细</span>
            </div> 
            <div style="line-height:30px ;height:35px;border-bottom:2px solid #389;display:flex;background:#f7f8f9;color:#409eff">
                <i class="iconfont icon-xuanze1" style="padding-left:3px;margin-right:4px;"></i>查询条件
                <el-select v-model="selparam3"  placeholder="请选择列名"   size="small" style="width:150px; margin-left:4px">
                    <span v-for="item in dtlCol" :key='item.key' >
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input clearable  :placeholder="input_hold" v-model="txtparam3" @keyup.enter.native="nextSet" size="small" style="width:320px; height:30px; ">
                    <el-select v-model="oper3" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO" ></el-option>
                        </span>
                    </el-select>
                    <el-button size="small" slot="append" icon="el-icon-search" @click="nextSet" ></el-button>
                </el-input>
            </div>
            <div style="line-height:30px ;height:35px;border-bottom:2px solid #389;display:flex;background:#f7f8f9;color:#409eff">
                <i class="iconfont icon-xuanze1" style="padding-left:3px;margin-right:4px;"></i>批量分配
                <el-checkbox @change="selChg" true-label="Y" false-label="N" v-model="allSel" style="line-height:30px;margin-left:15px;margin-right:4px; "><span style="font-size:10px">全选</span></el-checkbox>
                <el-checkbox @change="selNext" true-label="Y" false-label="N" v-model="allNext" style="line-height:30px;margin-left:15px;margin-right:4px; "><span style="font-size:10px">全下推</span></el-checkbox>
                <div style="line-height:30px;margin-left:5px;margin-right:4px;font-size:11px">供应商名称</div>
                <el-select v-model="v_supnm"   size="small" style="width:190px;" filterable>
                    <span v-for="(item,index) in supArr" :key='index'>
                        <el-option :label="item.SUPNM" :value="item.SUPNM"></el-option>
                    </span>
                </el-select>
                <div style="line-height:30px;margin-left:5px;margin-right:4px;font-size:11px">建议到货日期</div>
                <el-date-picker type="date" size="small"  v-model="v_shpdttm"   value-format="yyyy-MM-dd" style="width:135px" ></el-date-picker>  
                <div style="line-height:30px;margin-left:5px;margin-right:4px;font-size:11px">采购数量</div>
                <el-input-number :controls="false" type="text"  size="small" v-model="v_qty"  style="width:140px" ></el-input-number> 
                <el-checkbox true-label="Y" false-label="N" v-model="v_same" style="line-height:30px;margin-left:15px;margin-right:4px; "><span style="font-size:10px">与订单数相同</span></el-checkbox> 
                <el-button type="primary" @click="alloCate" size="small" icon="iconfont icon-shipeiqi" style="width:70px;margin-left:10px;height:30px;margin-top:2px;line-height:15px">分配</el-button>
            </div>
            <el-table  :data="poList" 
                border                            
                size="small"
                :row-key="getDtlKey"
                :height="maxHeight" 
                ref="refList"
                highlight-current-row > 
                <el-table-column v-for="(item,index ) in dtlCol.filter(item=>item.thide=='N')" :key="index"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    :sortable="item.edcss!=='checkbox'&&item.key!=='RN'"
                    :min-width="item.width">
                    <template  slot-scope="scope" >
                        <el-checkbox v-if="item.edcss=='checkbox'"  true-label="Y" :disabled="item.key=='ISADD' " false-label="N" v-model="scope.row[item.key]" style="width:100%"> &nbsp;</el-checkbox> 
                        <span v-else  v-text="scope.row[item.key]"> </span>
                    </template>
                </el-table-column> 
            </el-table>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <span style="float:right">
                    <el-button type="info" @click="poWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                    <el-button @click="genPo" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-shengcheng"  >生成采购单 </el-button> 
                </span>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import {paramData,getDate} from '../../api/Select' 
export default {
    name:'clnt_order',
    props: {
        v_cond :{type:String,default:''}
    },
    data () {
        return {
            v_shpdttm:'',
            v_supnm:'',
            v_qty:'',
            v_same:'N',
            supArr:[],
            allSel:'N',
            allNext:'N',
            poWin:false,
            poList:[],
            dtlWin:false,
            dtlRow:{},
            v_lstseq:'',
            promptWin:false,
            stfgSet:'ALL',
            prompt:'',
            input_hold:'请输入内容...',
            selparam:'IDNO',
            txtparam:'',
            oper:'like',
            selparam2:'',
            txtparam2:'',
            oper2:'like',
            ordRow:{},
            selparam3:'',
            txtparam3:'',
            oper3:'like',
            addWin:false,
            goPage:'',
            rowIndex:-1,
            poData:[],
            dtlData:[],
            loading:false,
            current:1,
            paymth:'ali',
            stfgArr:[],
            allList:[], //询价单集合
            matArr:[],
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
   
            dataCount:0,
            rowSize:10,
            dtlCol:[],
            ordCol:[],
            operArr:[],
            v_stfgnm:'',
            v_idseq:'-1',
            sumAmt:0,

        }
    },
    components: {
      //alipay,  
    },
    watch: {
        v_cond:function(){
            this.getOrdList(this.v_cond)
        }  
    },
    computed: {
        maxHeight: function() {
            return (window.innerHeight - 220) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 555) + 'px';
        }, 
        tabWidth: function() {
            return (window.innerWidth - 160) + 'px';
        }, 
    },
    mounted () {
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        })
        //订单状态
        paramData('PARENTNO','57015','V_SYS_BASE').then((res)=>{
            this.stfgArr =res.data.result
        })   
        getBaseData(this.$store.state.nologinUrl, '','','','','v_bs_sup',' and substr(supty,1,3)=\'236\'').then(res=>{
            this.supArr =res.data.result
        })
    },
    created () {
        if (this.v_username){
            //订单状态
            paramData('','','V_MAT_MIN').then((res)=>{
                this.matArr =res.data.result
            }) 
            this.getTabCol() //表列集合
            this.getOrdList(this.v_cond) //销售单主数据
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    methods: {
        alloCate(){
            this.poList.forEach((item,index)=>{   
                if (item.SEL=='Y'){
                    if (this.v_same=='Y'){ //采购数 
                        this.poList.splice(index,1,Object.assign(item,{'NEXTQTY':item['QTY']}))
                    }else{
                        if (this.v_qty && this.v_qty!=='0')
                          this.poList.splice(index,1,Object.assign(item,{'NEXTQTY':this.v_qty}))
                    }
                    if (this.v_supnm){
                        this.poList.splice(index,1,Object.assign(item,{'POSUP':this.v_supnm}))
                    }
                    if (this.v_shpdttm){
                        this.poList.splice(index,1,Object.assign(item,{'PODTTM':this.v_shpdttm}))
                    }
                }
            })          
        },
        getDtlKey(row){
            return  row['IDSEQ']+'-'+row['LSTSEQ']
        },
        //生成采购订单
        genPo(){
            let v_supFlag =false ,v_shpFlag =false,v_qtyFlag=false ,v_data=[]
            v_data =this.poList.filter(item=>item['ISNEXT']=='Y')
            if (v_data.length==0){
               this.promptWin =true
               this.prompt ='请选择要下推的记录' 
            }else{
                v_data.some(item=>{
                    if (!item['POSUP'] ){
                        v_supFlag =true
                    }
                    if (!item['PODTTM']){
                        v_shpFlag =true
                    }
                    if (!item['NEXTQTY']){
                        v_qtyFlag =true
                    }
                })
                if (v_supFlag){
                    this.promptWin =true
                    this.prompt ='请检查下推记录：建议供应商不能为空'
                }else if (v_shpFlag){
                    this.promptWin =true
                    this.prompt ='请检查记录：建议到货日期不能为空'
                }else if (v_qtyFlag){
                    this.promptWin =true
                    this.prompt ='请检查记录：下达数量不能为空'
                }else {
                    this.$confirm('你确定要下推生成采购订单吗','提示',{
                        confirmButtonText: '确定',
                        cancelButtonText:'取消',
                        customClass:'messageBox',
                        showClose:false,
                        closeOnClickModal:false,
                        closeOnPressEscape:false,
                        type:'info',
                    }).then(()=>{
                        let v_json =JSON.stringify(v_data)
                        let v_action =this.v_username+getDate('full')
                        this.$axios({   
                            method: 'post',
                            url:this.$store.state.baseUrl+'auth/next_genpo?username='+this.v_username+'&password='+this.v_password,
                            data: { corpid:this.v_corpid,p_json: v_json,p_user: this.v_username,p_action:v_action},
                        }).then(res=>{ 
                            if (res.data.code=='200'){        
                                this.$notify({
                                    title: '成功',
                                    message: '已成功下推生成采购单，请到采购单界面中查看',
                                    type: 'success'
                                });
 
                            }else{
                                this.promptWin =true
                                this.prompt ='下推失败！'+res.data.result
                            }
                            this.poWin =false
                        })
                    }).catch(()=>{})   
                }             
            }
        },
        selChg(val){
            this.poList.forEach(item=>{
                item['SEL']=this.allSel
            })
        },
        selNext(val){
            this.poList.forEach(item=>{
                item['ISNEXT']=this.allNext
            })
        },
        //下推请购明细
        nextSet(){
            this.allSel='N'
            this.allNext='N'
            let cond_=''
            if (this.selparam3 && this.txtparam3){ //列1
                if (this.oper3==='like'){
                    cond_=cond_+' and upper('+this.selparam3+') like upper(\'%' + this.txtparam3+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam3+')' +this.oper3+'upper(\''+this.txtparam3+'\')'
                }
            }
            var v_seq =''
            this.poData.forEach(item=>{
                if (item['SEL']=='Y'){
                    if (v_seq ){
                        v_seq =v_seq+',\''+item['IDSEQ']+'\''
                    }else{
                        v_seq ='\''+item['IDSEQ']+'\''
                    }
                }
            })
            if (!v_seq){
                this.promptWin =true
                this.prompt ='请选择订单记录'
            }else{
                this.poWin =true
                this.poList=[]
                getTabColOrData(this.v_url,this.v_username,this.v_password,'v_orditm','','','','','','',cond_+'  and idseq in ('+v_seq+') order by idseq,lstseq desc').then((res) => { 
                    this.poList =res.data
                    //console.log(JSON.stringify(this.poList))
                })
            }
        },
        numChg(){
            this.dtlRow['AMT'] =(Number(this.dtlRow['QTY']) * Number(this.dtlRow['PRC'])).toFixed(2)
        },
        matChg(val){
            this.dtlRow['MATNM']=''
            this.dtlRow['MATTYNM']=''
            this.dtlRow['MODELNM']=''
            this.dtlRow['MATDESC']=''
            this.dtlRow['SUPNM']=''
            this.dtlRow['FACIDNO']=''
            this.matArr.some(item=>{
                if(item['IDSEQ']==val){
                    this.dtlRow['MATNM']=item['MATNM']
                    this.dtlRow['MATTYNM']=item['MATTYNM']
                    this.dtlRow['MODELNM']=item['MODELNM']
                    this.dtlRow['MATDESC']=item['MATDESC']
                    this.dtlRow['SUPNM']=item['SUPNM']
                    this.dtlRow['FACIDNO']=item['FACIDNO']
                }
            })
        },
        addDtl(){
            this.dtlWin =true
            this.dtlRow={IDSEQ:this.v_idseq, ISADD:'Y',LSTSEQ:''}
        },
        editRec(row,index){
            this.rowIndex =index
            this.addWin =true
            this.ordRow =JSON.parse(JSON.stringify(row))
        },
        dtlEdit(row,index){
            if (row['ISADD']=='Y'){
                this.v_lstseq =row['LSTSEQ']
                this.dtlWin =true
                this.dtlRow =JSON.parse(JSON.stringify(row))
            }else{
                this.promptWin =true
                this.prompt ='客户订单记录不能编辑'
            }
        },
        stfgChg(val){
            
        },
        saveRec(){
            //获取状态中文名称
            this.stfgArr.some(item=>{
                if (item['SUBNO']==this.ordRow['STFG']){
                    this.v_stfgnm =item['CNAME']
                }
            })
            this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/formOneSave',
                data: {p_json:JSON.stringify(this.ordRow), p_table:'ord',frmid:''},
            }).then( res=>{                
                this.poData.splice(this.rowIndex,1,Object.assign(this.ordRow,{STFGNM: this.v_stfgnm}))
                this.addWin =false
            })
        },
        dtlSave(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username+'&password='+this.v_password,
                data: {p_json:JSON.stringify(this.dtlRow), p_table:'orditm',p_idseq:''},
            }).then(res=>{              
                this.getDtlList(this.v_idseq)
                this.dtlWin =false
            })
        },
        //对指定列进行合计
        getSummary(param){
            const {columns ,data}=param
            const sums=[]
            columns.forEach((column,index)=>{
                if (index===0){
                    sums[index]='合计'
                    return
                }
                const values =data.map(item=>Number(item[column.property]));
                if(column.property==='AMT'||column.property==='ADDFEE'||column.property==='SHPFEE'||column.property==='SERVFEE'){
                    sums[index]=values.reduce((prev,curr)=>{ //为数组中每一个元素依次执行回调函数
                        const value=Number(curr)
                        if(!isNaN(value)){
                            return Number(Number(prev) +Number(curr) ).toFixed(2)
                        }else{
                            return prev
                        }
                    },'')
                }
            })
            return sums
        },
        getDtlSum(param){
            const {columns ,data}=param
            const sums=[]
            columns.forEach((column,index)=>{
                if (index===1){
                    sums[index]='小计'
                    return
                }
                const values =data.map(item=>Number(item[column.property]));
                if(column.property==='AMT'||column.property==='QTY' ){
                    sums[index]=values.reduce((prev,curr)=>{ //为数组中每一个元素依次执行回调函数
                        const value=Number(curr)
                        if(!isNaN(value)){
                            return Number(Number(prev) +Number(curr) ).toFixed(2)
                        }else{
                            return prev
                        }
                    },'')
                }
            })
            return sums
        },
        //表行记录css
        tableRowClass({row, rowIndex}) {
            if (row.ORDSTFG  === 'Y') {
                return 'success-row';
            } else if (row.ORDSTFG ==='X') {
                return 'cancel-row';
            }
            return '';
        },
        getRowKey(row){
            return row.IDSEQ
        },
        getRowKey2(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        mailRec(row){
            this.$confirm('你确定要发送邮件通知吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                getTabColOrData(this.v_url,this.v_username,this.v_password,'v_CONTENT_SET','idseq','57240','','','','','' ).then((res) => { 
                    let v_note=res.data[0]['ENOTE'] +row['IDNO']
                    let v_title =res.data[0]['ETITLE']
                    //发送邮件给客户
                    if (row['MAILADDR'] ){
                        this.$axios({
                            method: 'post',
                            url:this.$store.state.baseUrl+'user/sendReqMail?username='+this.v_username+'&password='+this.v_password,
                            data: {'mailaddr':row['MAILADDR'],'title': v_title, p_note:v_note},
                        }).then(res=>{
                            if (res.data.code!=='200'){
                                this.promptWin=true
                                this.prompt=res.data.result
                            }else{
                                let v_dttm=getDate('full')
                                this.$axios({
                                    method: 'post',
                                    url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                                    data: {p_key:'IDNO',p_value:row['IDNO'],p_table:'ord',p_cond:' set ismail=\'Y\',maildttm=\''+v_dttm+'\' '},
                                }).then(res=>{ 
                                    this.poData.splice(this.rowIndex,1,Object.assign(row,{ISMAIL:'Y',MAILDTTM:v_dttm}))
                                })
                            }
                        })
                    }else{
                        this.prompt ='客户邮件地址不能为空'
                        this.promptWin =true
                    }
                })
            }).catch(()=>{})
        },
        //删除记录
        delRec(row ){
            if (row['ISADD']!=='Y'){
                this.promptWin=true
                this.prompt ='客户订单记录不能删除'
            }else{
                this.$confirm('你确定要删除此记录吗?','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    type:'info',
                }).then(()=>{
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ, p_table:'orditm'},
                    }).then(res=>{     
                        if(res.data.code =='200')  {           
                            this.getDtlList(this.v_idseq)
                        }else{
                            this.promptWin =true
                            this.prompt=res.data.result
                        }        
                    })
                }).catch(()=>{})
            }
        },
        // 行点击事件
        rowClick(row){
            this.v_idseq =row['IDSEQ']
             this.getDtlList(row.IDSEQ)
        },
        //状态查询
        stfgChange(){
            let cond_=this.v_cond
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and upper('+this.selparam+') like upper(\'%'+this.txtparam+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam+')'+this.oper+'upper(\''+this.txtparam+'\')'
                }
            }
            if (this.selparam2){
                if (this.oper2==='like'){
                    cond_=cond_+' and upper('+this.selparam2+') like upper(\'%'+this.txtparam2+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam2+')'+this.oper2+'upper(\''+this.txtparam2+'\')'
                }
            }
            if (this.stfgSet!=='ALL'){
               cond_=cond_+' and  stfg=\''+this.stfgSet+'\''
            }
            this.getOrdList(cond_)
        }, 
        //客户订单 
        getOrdList(cond){
            let vm =this
            this.sumAmt=0
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_ord','','','','','','',cond+' order by adddttm desc' ).then((res) => { 
                this.poData =res.data 
                this.dtlData=[]
                if (res.data.length>0){
                    this.v_idseq=res.data[0]['IDSEQ']
                    this.$refs['refpo'].setCurrentRow(res.data[0])
                    this.getDtlList(res.data[0].IDSEQ)
                } 
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    if (this.$refs.refpo.doLayout){
                        this.$refs.refpo.doLayout();
                    }
                }) 
            })
        },
        //询价单明细
        getDtlList(idseq){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_orditm','idseq',idseq,'','','','',' order by adddttm' ).then((res) => { 
                this.dtlData =res.data 
                this.$nextTick(()=>{
                    this.$refs['refdtl'].doLayout()
                })
            })
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','115','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.ordCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE,
                        disabled: res.data[k].DISABLED,  
                        edcss: res.data[k].EDCSS,                 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','115','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.dtlCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE, 
                        edcss: res.data[k].EDCSS,                     
                        disabled: res.data[k].DISABLED, 
                    })
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
</style>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
 
</style>